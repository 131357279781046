import {Grid, Image, Text} from "@nextui-org/react";
import {BaseWrapper} from "../components/BaseWrapper";

export const Home = () => {
    return (
        <BaseWrapper>
            <LandingPageBanner/>
        </BaseWrapper>
    )
}

function LandingPageBanner() {
    return (

        <Grid.Container justify={"center"} css={{"display": "grid"}}>
            <Grid >
                <Text h1>Welcome</Text>
            </Grid>
            <Grid >
                <Text h3>Oh, Egg-cellent, why do you need to know the reason behind everything? </Text>
                <Text h3>Just use it for now and we can have a chat about it later. Trust me, I'll make it worth your while. ;)</Text>
            </Grid>
        </Grid.Container>

    );
}
