import {Table, Text, useAsyncList, useCollator} from "@nextui-org/react";
import {BaseWrapper} from "../components/BaseWrapper";
import {useAuth0} from "@auth0/auth0-react";
import {getListEggs} from "../services/message.service";

export const ViewEggs = () => {
    const collator = useCollator({numeric: true});
    const {getAccessTokenSilently} = useAuth0();

    async function load({signal}) {
        const accessToken = await getAccessTokenSilently();
        const {data} = await getListEggs(accessToken);
        return {
            items: data || [],
        };
    }

    async function sort({items, sortDescriptor}) {
        return {
            items: items?.sort((a, b) => {
                let first = a[sortDescriptor.column];
                let second = b[sortDescriptor.column];
                let cmp = collator.compare(first, second);
                if (sortDescriptor.direction === "descending") {
                    cmp *= -1;
                }
                return cmp;
            }),
        };
    }


    const list = useAsyncList({load, sort});
    return (
        <BaseWrapper>
            <Text h2>List of JSON Bodies.</Text>
            <Text>Below is a list of JSON bodies that you can created.</Text>
            <Table bordered striped
                   aria-label="Example static collection table"
                   css={{minWidth: "100%", height: "calc($space$14 * 10)"}}
                   sortDescriptor={list?.sortDescriptor}
                   onSortChange={list?.sort}
                   selectionMode="single"
            >
                <Table.Header>
                    <Table.Column key="name" allowsSorting>
                        Name
                    </Table.Column>
                    <Table.Column key="url">
                        URL
                    </Table.Column>
                    <Table.Column key="public" allowsSorting>
                        Public
                    </Table.Column>
                    <Table.Column key="created" allowsSorting>
                        Created
                    </Table.Column>
                    <Table.Column key="updated" allowsSorting>
                        Updated
                    </Table.Column>

                </Table.Header>
                <Table.Body items={list.items} loadingState={list.loadingState}>
                    {(item) => (
                        <Table.Row key={item.name}>
                    {(columnKey) => <Table.Cell>{item[columnKey]}</Table.Cell>}
                        </Table.Row>
                        )}
                </Table.Body>
            </Table>
        </BaseWrapper>
    );
}

export default ViewEggs;
