import React, {useEffect, useState} from 'react';
import {createRoot} from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import {createTheme, getDocumentTheme, NextUIProvider} from "@nextui-org/react";
import App from "./App";
import {Auth0ProviderWithNavigate} from "./auth0-provider-with-navigate";

const container = document.getElementById("root");
const root = createRoot(container);

// const brand = ['#dcae8d', '#ff7700']
// const brandBase = ['#f3f5f7', '#3c3e3f', '#1f1a1a']
// const success = ['#4caf50', '#8bc34a']
// const warning = ['#ff9800', '#ff5722']
// const error = ['#f44336', '#ff0000']

const lightTheme = createTheme({
    type: 'light',
    theme: {
        colors: {
            // ...generateColors("primary", brand[0]),
            // ...generateColors("secondary", brand[1]),
            // ...generateColors("success", success[0]),
            // ...generateColors("warning", warning[0]),
            // ...generateColors("error", error[0]),
            // gradient: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            // background: brandBase[0],
            // text: brandBase[1],
            // textLight: brandBase[1],
            // textContrast: brand[1],
            // buttonTextColor:'red'

        },

    }
})

const darkTheme = createTheme({
    type: 'dark',
    theme: {
        colors: {
            // ...generateColors("primary", brand[1]),
            // ...generateColors("secondary", brand[0]),
            //     ...generateColors("success", success[1]),
            //     ...generateColors("warning", warning[1]),
            //     ...generateColors("error", error[1]),
            //
            //     gradient: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            //
            //     linkLight: '',
            //     link: '',
            //     codeLight: '',
            //     code: '',
            //     selection: '',
            //     border: '',
            //     background: brandBase[2],
            //     // backgroundAlpha: brandBase[1],
            //     backgroundContrast: brandBase[1],
            //     // foreground: '',
            //     // text: brandBase[0],
            //     // textLight: brandBase[0],
            //     // textContrast: brand[0],
        },
    }
})



const Master = () => {
    const [isDark, setIsDark] = useState(false);

    useEffect(() => {
        // you can use any storage
        let theme = window.localStorage.getItem('data-theme');
        setIsDark(theme === 'dark');

        const observer = new MutationObserver((mutation) => {
            let newTheme = getDocumentTheme(document?.documentElement);
            setIsDark(newTheme === 'dark');
        });

        // Observe the document theme changes
        observer.observe(document?.documentElement, {
            attributes: true,
            attributeFilter: ['data-theme', 'style']
        });

        return () => observer.disconnect();
    }, []);

    return (
        <BrowserRouter>
            <NextUIProvider theme={isDark ? darkTheme : lightTheme}>
                <Auth0ProviderWithNavigate>
                    <App/>
                </Auth0ProviderWithNavigate>
            </NextUIProvider>
        </BrowserRouter>
    )
}

root.render(<Master/>);
