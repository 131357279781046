import {Button, Container, Grid, Input, Spacer, Switch, Text, Textarea} from "@nextui-org/react";
import {BaseWrapper} from "components/BaseWrapper";
import {useAuth0} from "@auth0/auth0-react";
import {createEggs} from "services/message.service";
import {useEffect, useState} from "react";

export const CreateEggs = () => {
    const {getAccessTokenSilently} = useAuth0();

    const [name, setName] = useState("");
    const [content, setContent] = useState("");
    const [isPublic, setIsPublic] = useState(false);
    const [canSubmit, setCanSubmit] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [result, setResult] = useState(null);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        setCanSubmit(name.trim() !== "" && content.trim() !== "");
    }, [name, content]);

    const handleSubmit = async () => {
        setIsSubmitting(true);

        try {
            const token = await getAccessTokenSilently();
            const jsonData = {name: name, data: content, isPublic};
            const {respData, respError} = await createEggs(token, jsonData);
            if (respError) {
                setError(true);
                setErrorMsg(respError?.message || "An unknown error occurred.");
            }
            if (respData && respData?.code.toString() === "201") {
                setResult(respData);
                setIsSubmitted(true);
            }
        } catch (error) {
            setError(true);
            setErrorMsg(error || "An unknown error occurred.");
        } finally {
            setIsSubmitting(false);
        }
    };

    const renderSubmissionStatus = () => {
        if (isSubmitting) {
            return (
                <>
                    <Text h3>Submitting...</Text>
                    <Text>Please wait while we submit your JSON body.</Text>
                </>
            );
        }

        if (isSubmitted) {
            return (
                <>
                    <Text h3>Submitted!</Text>
                    <Text>Your JSON body has been submitted.</Text>
                    <Text>Here is the URL to your JSON body:</Text>
                    <Text>{result?.message || ''}</Text>
                </>
            );
        }

        if (error) {
            return (
                <>
                    <Text h3>Error!</Text>
                    <Text>There was an error submitting your JSON body.</Text>
                    <Text>{errorMsg}</Text>
                </>
            );
        }

        return null;
    };

    return (
        <BaseWrapper>
            <Container sm>
                <Text h2>Create JSON Body.</Text>
                {!isSubmitted && !isSubmitting && (
                    <>
                        <Text>Please fill out the form below to create a new JSON body.</Text>
                        <Spacer y={0.5}/>

                        <Grid.Container gap={2} justify="center">
                            <Grid>
                                <Input
                                    clearable
                                    bordered
                                    labelPlaceholder="Name"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Grid>

                            <Grid xs={12} justify={"center"}>
                                <Textarea
                                    width={"100%"}
                                    bordered
                                    color="secondary"
                                    labelPlaceholder="Json Body"
                                    value={content}
                                    onChange={(e) => setContent(e.target.value)}
                                />
                            </Grid>

                            <Grid xs={12} justify={"center"}>
                                <Grid>
                                    <Text>Make this JSON body public?</Text>
                                </Grid>
                                <Grid>
                                    <Switch
                                        name={"public"}
                                        color={"error"}
                                        checked={isPublic}
                                        onChange={(e) => setIsPublic(e.target.checked)}
                                    >Public</Switch>
                                </Grid>
                            </Grid>
                            <Button
                                disabled={!canSubmit}
                                type={"success"}
                                onPress={() => handleSubmit()}
                            >
                                Submit
                            </Button>
                        </Grid.Container>
                    </>
                )}
                {renderSubmissionStatus()}
            </Container>
        </BaseWrapper>
    );
};

