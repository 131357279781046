import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import {Grid, Loading} from "@nextui-org/react";


export const AuthGuard = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
        <Grid.Container justify="center" alignItems="center" style={{height: "100vh"}}>
          <Grid>
            <Loading color="primary" type="points" loadingCss={{$$loadingSize: "40px", $$loadingBorder: "10px"}}/>
          </Grid>
        </Grid.Container>
    ),
  });

  return <Component />;
};
