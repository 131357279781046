import {useAuth0} from "@auth0/auth0-react";
import {Grid, Loading} from "@nextui-org/react";
import {Home} from "./pages/Home";
import {Route, Routes} from "react-router-dom";
import {PageNotFound} from "./pages/PageNotFound";
import {ViewEggs} from "./pages/ViewEggs";
import {AuthGuard} from "./components/AuthGuard";
import {CreateEggs} from "./pages/CreateEggs";

const App = () => {
    const {isLoading} = useAuth0();

    if (isLoading) {
        return (
            <Grid.Container justify="center" alignItems="center" style={{height: "100vh"}}>
                <Grid>
                    <Loading color="primary" type="points" loadingCss={{$$loadingSize: "40px", $$loadingBorder: "10px"}}/>
                </Grid>
            </Grid.Container>
        );
    }
    return (
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/index" element={<Home/>}/>

            {/*<Route path="/public" element={<PublicPage/>}/>*/}
            {/*<Route path="/callback" element={<CallbackPage/>}/>*/}

            {/*<Route path="/profile" element={<AuthGuard component={ProfilePage}/>}/>*/}
            {/*<Route path="/protected" element={<AuthGuard component={ProtectedPage}/>}/>*/}
            {/*<Route path="/admin" element={<AuthGuard component={AdminPage}/>}/>*/}


            {/*Eggs*/}
            <Route path="/eggs" element={<AuthGuard component={ViewEggs}/>}/>
            <Route path="/create-eggs" element={<AuthGuard component={CreateEggs}/>}/>


            <Route path="*" element={<PageNotFound/>}/>
        </Routes>
    );
}

export default App;
