import {Container, Spacer} from "@nextui-org/react";
import {RestNestNavbar as Navbar} from "components/Navbar/Navbar";

export const BaseWrapper = ({children}) => {
    return (
        <>
            <Navbar/>
            <Container>
                <Spacer y={2}/>
                {children}
            </Container>
        </>
    )
}
