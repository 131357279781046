import {callExternalApi} from "./external-api.service";
import {formatDate} from "utils/formateDate";
const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;
const apiServerVersion = process.env.REACT_APP_API_SERVER_VERSION;

export const getPublicResource = async () => {
    const config = {
        url: `${apiServerUrl}/api/messages/public`,
        method: "GET",
        headers: {
            "content-type": "application/json",
        },
    };

    const {data, error} = await callExternalApi({config});

    return {
        data: data || null,
        error,
    };
};

export const getProtectedResource = async (accessToken) => {
    const config = {
        url: `${apiServerUrl}/api/messages/protected`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        },
    };

    const {data, error} = await callExternalApi({config});

    return {
        data: data || null,
        error,
    };
};

export const getAdminResource = async (accessToken) => {
    const config = {
        url: `${apiServerUrl}/api/messages/admin`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        },
    };

    const {data, error} = await callExternalApi({config});

    return {
        data: data || null,
        error,
    };
};


export const getListEggs = async (accessToken) => {
    const config = {
        url: `${apiServerUrl}/${apiServerVersion}/eggs`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        },
    };

    const {data, error} = await callExternalApi({config});
    const remappedData = data?.data.map((item, id) => {
        return {
            id: item._id,
            name: item.name,
            url: item.url,
            public : (item.public) ? "Yes" : "No",
            created : formatDate(item.created_at),
            updated : formatDate(item.updated_at)
        };
    });
    return {
        data: remappedData || null,
        error,
    };
}

export const createEggs = async (accessToken, json) => {
    const config = {
        url: `${apiServerUrl}/${apiServerVersion}/egg`,
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        },
        data: {
            name: json.name,
            data : json.data,
            public : Boolean(json.public)
        },
    };


    const {data, error} = await callExternalApi({config});
    return {
        respData: data || null,
        respError: error,
    };
}
