import {Button, Grid, Spacer, Text} from "@nextui-org/react";
import {useNavigate} from "react-router-dom";
import {Icon} from "../components/Icons/Icons";

export const PageNotFound = () => {
    const navigate = useNavigate();
    return (
        <Grid.Container justify="center" alignItems="center" style={{height: "100vh"}}>
            <Grid css={{"text-align": "center"}}>
                <Grid css={{"font-size": "3rem"}}>
                    <Icon name="circle-4" type={"regular"}/>
                    <Icon name="circle-0" type={"regular"}/>
                    <Icon name="circle-4" type={"regular"}/>
                </Grid>
                <Spacer y={1}/>
                <Text b h3 css={{"text-align": "center"}}>Page Not Found</Text>
                <Button.Group vertical bordered>
                    <Button color={"primary"} onPress={() => navigate("/")}>
                        <Icon name="home"/>
                    </Button>
                    <Button color={"primary"} onPress={() => navigate("/profile")}>
                        <Icon name="user"/>
                    </Button>
                </Button.Group>
            </Grid>
        </Grid.Container>
    )
}
